import React, { useState } from 'react';
import styles from './CurrencyForm.module.css';
import {nanoid} from "nanoid";
import {useCurrencyUpserter} from "../../entities/Currency/useCurrencyUpserter.ts";

interface CurrencyFormProps {
    onSubmit: (currencyData: CurrencyData) => void;
    initialData?: CurrencyData;
}

export interface CurrencyData {
    _id: string;
    name: string;
    code: string;
    symbol: string;
    decimalPlaces: number;
    isCrypto: boolean;
}

const CurrencyForm: React.FC<CurrencyFormProps> = ({ onSubmit, initialData }) => {
    const [currencyData, setCurrencyData] = useState<CurrencyData>({
        _id: initialData?._id || '',
        name: initialData?.name || '',
        code: initialData?.code || '',
        symbol: initialData?.symbol || '',
        decimalPlaces: initialData?.decimalPlaces || 2,
        isCrypto: initialData?.isCrypto || false,
    });
    const { loading, result, error, update } = useCurrencyUpserter()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setCurrencyData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const id = currencyData._id || `currency:${nanoid()}`;

        await update(id, (doc: any) => ({
            ...doc,
            ...currencyData,
            _id: id,
            type: 'currency',
            version: 1,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        }))
        onSubmit(currencyData);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {result && <p>Saved!</p>}

            <div className={styles.formGroup}>
                <label htmlFor="name" className={styles.label}>Currency Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    className={styles.input}
                    value={currencyData.name}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="code" className={styles.label}>Currency Code</label>
                <input
                    type="text"
                    id="code"
                    name="code"
                    className={styles.input}
                    value={currencyData.code}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="symbol" className={styles.label}>Currency Symbol</label>
                <input
                    type="text"
                    id="symbol"
                    name="symbol"
                    className={styles.input}
                    value={currencyData.symbol}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="decimalPlaces" className={styles.label}>Decimal Places</label>
                <input
                    type="number"
                    id="decimalPlaces"
                    name="decimalPlaces"
                    className={styles.input}
                    value={currencyData.decimalPlaces}
                    onChange={handleChange}
                    min="0"
                    required
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="isCrypto" className={styles.label}>
                    <input
                        type="checkbox"
                        id="isCrypto"
                        name="isCrypto"
                        className={styles.checkbox}
                        checked={currencyData.isCrypto}
                        onChange={handleChange}
                    />
                    Is Cryptocurrency
                </label>
            </div>

            <button type="submit" className={styles.button}>Save</button>
        </form>
    );
};

export default CurrencyForm;
