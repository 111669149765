import React from "react";
import {Link} from "wouter";
import styles from './AccountsListPage.module.css';

const plans = [
    {
        id: 1,
        name: 'Cash',
    },
    {
        id: 2,
        name: 'Altyn-i',
    },
    // More plans...
]

// function classNames(...classes: string[]) {
//     return classes.filter(Boolean).join(' ')
// }

export const AccountsListPage: React.FC = () => {
    return (
        <div>
            <h1>Accounts</h1>
            <Link className="button" href="/accounts/new">New Account</Link>
            <ul>
                {plans.map((plan) => (
                    <li key={plan.id} className={styles.item}>
                        <Link href={`/accounts/${plan.id}`}>{plan.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

