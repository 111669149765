import {useState} from "react";
import {getDocumentOrNull} from "./useDocument.ts";

const UPDATE_ATTEMPTS = 100;

async function updateDocument(db: PouchDB.Database, docId: string, updater: (doc: any) => any): Promise<any> {
    for (let i = 0; i < UPDATE_ATTEMPTS; i++) {
        try {
            const doc = await getDocumentOrNull(db, docId)
            return await db.put(updater(doc))
        } catch (err: any) {
            if (err.name !== 'conflict') {
                throw err
            }
        }
    }

    throw new Error(`Failed to update document after ${UPDATE_ATTEMPTS} attempts`)
}

export function useDocumentUpdater(db: PouchDB.Database) {
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState<any>(null);
    const [error, setError] = useState<Error | null>(null);
    const update = (docId: string, updater: (doc: any) => any) => updateDocument(db, docId, updater)
        .then(doc => setResult(doc))
        .catch(err => setError(err))
        .finally(() => setLoading(false))

    // useEffect(() => {
    //     updateDocument(db, docId, updater)
    //         .then(doc => setResult(doc))
    //         .catch(err => setError(err))
    //         .finally(() => setLoading(false))
    // }, [db, docId, updater]);

    return {loading, result, error, update};
}
