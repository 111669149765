import React, {PropsWithChildren} from 'react';
import styles from './Layout.module.css';
import Header from "../Header/Header.tsx";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div>
            <Header/>
            <header className={`${styles.container} mt-4`}>
                <h1 className={styles.dashboard}>Dashboard</h1>
            </header>
            <main className={`${styles.container} mt-4`}>
                <div className={styles.content}>
                    {children}
                </div>
            </main>
        </div>
    );
};

export default Layout;
