import PWABadge from './PWABadge.tsx'
import Layout from "./components/Layout/Layout.tsx";
import {Route, Switch} from "wouter";
import {HomePage} from "./pages/Home";
import {Error404NotFoundPage} from "./pages/Error404NotFound/Error404NotFoundPage.tsx";
import {AccountsListPage} from "./pages/Accounts/AccountsListPage.tsx";
import {TransactionsListPage} from "./pages/Transactions/TransactionsListPage.tsx";
import CurrencyFormPage from "./pages/Currencies/CurrencyForm/CurrencyFormPage.tsx";
import {CurrenciesListPage} from "./pages/Currencies/CurrenciesList/CurrenciesListPage.tsx";

function App() {
  return (
      <>
          <Layout>
              <Switch>
                  <Route path="/" component={HomePage} />
                  <Route path="/accounts" component={AccountsListPage} />
                  <Route path="/accounts/new" component={AccountsListPage} />
                  <Route path="/currencies" component={CurrenciesListPage} />
                  <Route path="/currency/new" component={CurrencyFormPage} />
                  <Route path="/transactions" component={TransactionsListPage} />
                  <Route component={Error404NotFoundPage}/>
              </Switch>
          </Layout>
          <PWABadge/>
      </>
  )
}

export default App
