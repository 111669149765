import React from "react";
import {Link} from "wouter";
import styles from './CurrenciesListPage.module.css';
import {useCurencies} from "../../../entities/Currency/useCurrencyUpserter.ts";

// function classNames(...classes: string[]) {
//     return classes.filter(Boolean).join(' ')
// }

export const CurrenciesListPage: React.FC = () => {
    const { loading, docs, error } = useCurencies()
    return (
        <div>
            <h1>Currencies</h1>
            <Link className="button" href="/currency/new">New Currency</Link>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}

            <ul>
                {docs.map((doc) => (
                    <li key={doc._id} className={styles.item}>
                        <Link href={`/currencies/${doc._id}`}>{doc.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

