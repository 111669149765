import {useEffect, useState} from "react";

export function useAllDocs(db: PouchDB.Database, options: any) {
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState<any[]>([]);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        db.allDocs({
                include_docs: true,
                attachments: true,
                startkey: options.startkey,
                endkey: options.endkey,
            })
            .then((result: any) => setDocs(result.rows.map((row: any) => row.doc)))
            .catch((err: any) => setError(err))
            .finally(() => setLoading(false));
    }, [db, options]);

    return {loading, docs, error};

    // db.allDocs({
    //     include_docs: true,
    //     attachments: true,
    //     startkey: 'foo',
    //     endkey: 'foo\ufff0'
    // }).then(function (result) {
    //     // handle result
    // }).catch(function (err) {
    //     console.log(err);
    // });
}
