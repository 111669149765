import React from 'react';
import styles from './Header.module.css';
import {Link} from "wouter";

const Header: React.FC = () => {
    return (
        <nav className="bg-white shadow">
            <div className={`${styles.container} flex justify-between items-center h-16`}>
                <Link href="/">
                    <div className="flex-shrink-0">
                        <span className="text-2xl font-semibold text-blue-500">Logo</span>
                    </div>
                </Link>
                <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                        <Link href="/accounts" className="text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Accounts</Link>
                        <Link href="/currencies"
                           className="text-gray-500 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Currencies</Link>
                        <Link href="/transactions"
                           className="text-gray-500 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Transactions</Link>
                    </div>
                </div>
                <div className="ml-4 flex items-center md:ml-6">
                    <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white">
                        <span className="sr-only">View notifications</span>
                        {/* Notification Icon */}
                    </button>
                    <div className="ml-3 relative">
                        <div>
                            <button
                                className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none">
                                <img className="h-8 w-8 rounded-full" src="https://via.placeholder.com/150" alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
