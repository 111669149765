import {useEffect, useState} from "react";

export async function getDocumentOrNull(db: PouchDB.Database, docId: string): Promise<any> {
    try {
        return await db.get(docId);
    } catch (err: any) {
        if (err.status === 404) {
            return null;
        } else {
            throw err;
        }
    }
}

export function useDocument(db: PouchDB.Database, docId: string) {
    const [loading, setLoading] = useState(true);
    const [doc, setDoc] = useState<any>(null);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        getDocumentOrNull(db, docId)
            .then(doc => setDoc(doc))
            .catch(err => setError(err))
            .finally(() => setLoading(false))
    }, [db, docId]);

    return {loading, doc, error};
}
