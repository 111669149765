import {usePouch} from "../../hooks/usePouch.ts";
import {useDocumentUpdater} from "../../shared/api/pouchdb/useDocumentUpdater.ts";
import {useAllDocs} from "../../shared/api/pouchdb/useDocuments.ts";

const ENTITY_TYPE = 'currency';

export function useCurrencyUpserter() {
    return useDocumentUpdater(usePouch())
}

export function useCurencies() {
    return useAllDocs(usePouch(), {
        startkey: `${ENTITY_TYPE}:`,
        endkey: `${ENTITY_TYPE}:\ufff0`,
    })
}