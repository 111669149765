import {useRef} from "react";
import PouchDB from 'pouchdb-browser'

export function usePouch(): PouchDB.Database {
    const dbRef = useRef<PouchDB.Database | null>(null);

    if (!dbRef.current) {
        dbRef.current = new PouchDB('_local');
    }

    return dbRef.current;
}