import React from 'react';
import CurrencyForm from "../../../components/CurrencyForm/CurrencyForm.tsx";

const CurrencyFormPage: React.FC = () => {
    const handleSubmit = () => {
        console.log('submit');
    }

    return (
        <CurrencyForm onSubmit={handleSubmit}/>
    );
};

export default CurrencyFormPage;
