import './PWABadge.css'

import { useRegisterSW } from 'virtual:pwa-register/react'
import {useEffect, useState} from "react";
import ConfirmDialog from "./components/ConfirmDialog/ConfirmDialog.tsx";

function PWABadge() {
  // check for updates every hour
  // const period = 60 * 60 * 1000
  const period = 1000

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (period <= 0) return;
      if (r?.active?.state === 'activated') {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener('statechange', (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === 'activated') {
            registerPeriodicSync(period, swUrl, r);
          }
        });
      }
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  function close() {
    setNeedRefresh(false);
    setIsModalOpen(false);
  }

  function handleUpdate() {
    updateServiceWorker(true);
    close();
  }

  // Открываем модальное окно, когда появляется уведомление о необходимости обновления
  useEffect(() => {
    if (needRefresh) {
      setIsModalOpen(true);
    }
  }, [needRefresh]);

  return (
      <ConfirmDialog
          isOpen={isModalOpen}
          onClose={close}
          onConfirm={handleUpdate}
          title="Update Available"
          message="New content is available. Would you like to update the application? This will refresh the page."
          confirmButtonText="Reload"
          cancelButtonText="Close"
          confirmButtonClassName="bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600"
          cancelButtonClassName="bg-white text-gray-900 ring-gray-300 hover:bg-gray-50"
          icon={
            <svg
                aria-hidden="true"
                className="h-6 w-6 text-blue-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v1m0 10v1m-7.071-7.071l.707-.707m12.728 0l-.707-.707M4 12h1m10 0h1m-7.071 7.071l.707.707m12.728-12.728l-.707-.707M12 4a8 8 0 110 16 8 8 0 010-16z"
              />
            </svg>
          }
      />
  );
}

export default PWABadge

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
function registerPeriodicSync(period: number, swUrl: string, r: ServiceWorkerRegistration) {
  if (period <= 0) return

  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine)
      return

    const resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        'cache': 'no-store',
        'cache-control': 'no-cache',
      },
    })

    if (resp?.status === 200)
      await r.update()
  }, period)
}
